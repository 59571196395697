.react-datepicker__header {
  background-color: #e7f2f9;
  border-bottom: 1px solid #e7f2f9;
}
.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker {
  display: flex;
}
.react-datepicker__header--time {
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 12px;
}
